import React from "react";
import ReactDOM from "react-dom/client";
import { useState } from 'react';

import Navigation from './components/Navigation';
import Players from './pages/Players';
import Economy from "./pages/Economy";
import Achievements from "./pages/Achievements";
import CommunityCenter from './pages/CommunityCenter';
import "./index.scss";

import {
  set_global_item_names,
} from "./services/Util";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {sendGetRequest} from "./services/ApiService";

const INTERVAL_PLAYER_KILL_UPDATE = 1000;
const PubNub = require('pubnub');

let pubnub = new PubNub({
  subscribeKey: "sub-c-abb020aa-f315-49a9-bc78-029dc86211b5",
  userId: "sunday-drivers-website",
});

// Subscribe to updates.
pubnub.subscribe({
  channels: ["sunday-drivers"],
});

let pubnubListener = undefined;

let players_copy = [];

const App = () => {

  const [selling, setSelling] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [bestsellers, setBestsellers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [safehouses, setSafehouses] = useState([]);
  const [shops, setShops] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [selectedSellers, setSelectedSellers] = useState([]);

  const onSellerToggle = (seller) => {

    var newSet = selectedSellers.map(seller => {
      return seller;
    });

    setSelectedSellers(newSet.includes(seller) ? newSet.filter(i => i !== seller) : [...newSet, seller]);
  }

  /**
   * Process full or partial new data for players.
   *
   */
  function process_player_data({players}) {
    players.forEach(fresh_entry => {

      // Find or create a player object in the state.
      var existing_record = players_copy.find(obj => (obj.name === fresh_entry.name));
      if (existing_record === undefined) {
        existing_record = fresh_entry;
        players_copy.push(existing_record);
      }

      if (Math.abs(fresh_entry.kills - existing_record.kills) < 1)
        existing_record.kills = fresh_entry.kills;

      existing_record.active = (existing_record.kills !== fresh_entry.kills);

      existing_record.hours = fresh_entry.hours;
      existing_record.last_login = fresh_entry.last_login;
      existing_record.sprinters = fresh_entry.sprinters;
      existing_record.rescues = fresh_entry.rescues;
      if (existing_record.sprinters > existing_record.kills)
        existing_record.sprinters = existing_record.kills;

      existing_record.kills_last_update = fresh_entry.kills;

      existing_record.kill_delta_per_interval = ((fresh_entry.kills - existing_record.kills) / 30);
    })

    setPlayers(JSON.parse(JSON.stringify(players_copy)));
  }

  /**
   * Process achievements, creating sort keys (1, 5, 10 instead of 10, 1, 5).
   *
   */
  function process_achievements({achievements}) {
    setAchievements(
        achievements.map((achievement) => {
          let key = achievement.achievement_key.toLowerCase();

          // Killing achievements
          if (key.includes("killed")) {
            key = key.substring(key.indexOf("killed"));
            achievement.achievement_key = key;
            achievement.sort_key = parseInt(key.replace(/\D/g, ''));
          }
          else if (key.includes("rescued")) { // Rescuer
            key = key.substring(key.indexOf("rescued"));
            achievement.achievement_key = key.toLowerCase();
            achievement.sort_key = 0;
          }
          else if (key.includes("winter")) { // Winter
            key = key.substring(key.indexOf("winter"));
            achievement.achievement_key = key.toLowerCase();
            achievement.sort_key = 0;
          }
          else if (key.includes("complete")) { // Pokemon album
            key = key.substring(key.indexOf("complete"));
            achievement.achievement_key = key.toLowerCase();
            achievement.sort_key = 0;
          }
          else if (key.includes("explorer")) { // Explorer
            key = key.substring(key.indexOf("explorer"));
            achievement.achievement_key = key.toLowerCase();
            achievement.sort_key = 0;
          }
          else if (key.includes("sprinters")) { // Sprinters
            key = key.substring(key.indexOf("sprinters"));
            achievement.achievement_key = key.toLowerCase();
            achievement.sort_key = 0;
          }
          return achievement;
        })
    );
  }

  function process_shops({shops}) {
    setShops(shops);
  }

  function process_purchases({purchases}) {
    var filtered = purchases.filter(item => item.item.search("pkmncards") !== 0);
    var sorted = filtered.slice(-50).sort((a, b) => { return (b.id - a.id) });

    setPurchases(sorted);

    // Best sellers
    var newBestsellers = [];
    purchases
      .forEach(item => {
        var existing = newBestsellers.find(obj => (obj.item === item.item));
        if (existing === undefined) {
          existing = {
            item: item.item,
            quantity: item.quantity
          }
          newBestsellers.push(existing);
        }
        else {
          existing.quantity += item.quantity;
        }
      });

    setBestsellers(newBestsellers
      .sort((a, b) => { return (b.quantity - a.quantity) })
      .slice(0, 30));
  }

  function process_selling_data({selling}) {
    var result = [];

    selling.forEach(item => {
      var existing = result.find(obj => (obj.item === item.item));
      if (existing === undefined) {
        existing = {
          id: item.id,
          item: item.item,
          sellers: [item.owner],
          quantity: 1,
          created_at: item.created_at
        }
        result.push(existing);
      }
      else {
        existing.sellers.push(item.owner);
        existing.quantity++;
      }
    });

    setSelling(result);
  }

  function process_safehouses_data({safehouses}) {
    setSafehouses(
        safehouses.filter(safehouse => {
          return safehouse.x1 >= 11100 &&
              safehouse.y1 >= 8760 &&
              safehouse.x2 <= 11400 &&
              safehouse.y2 <= 9000;
        })
    );
  }

  const retrieveEconomyData = () => {
    sendGetRequest('api/v1/economy/purchases', process_purchases);
    sendGetRequest('api/v1/economy/selling', process_selling_data);
    sendGetRequest('api/v1/economy/shops', process_shops);
  }

  React.useEffect(() => {
    sendGetRequest('safehouses.json', process_safehouses_data);
    sendGetRequest('all-items.json', set_global_item_names);
    sendGetRequest('api/v1/economy/purchases', process_purchases);
    sendGetRequest('api/v1/economy/selling', process_selling_data);
    sendGetRequest('api/v1/economy/shops', process_shops);
    sendGetRequest('api/v1/achievements', process_achievements);
    sendGetRequest('api/v1/players', process_player_data);

    // add listener
    if (pubnubListener === undefined) {
      pubnubListener = {
        status: (statusEvent) => {
          if (statusEvent.category === "PNConnectedCategory") {
            console.log("Connected");
          }
        },
        message: (messageEvent) => {
          console.log("new message arrived");
          sendGetRequest('api/v1/players', process_player_data);
        },
        presence: (presenceEvent) => { }
      };
      pubnub.addListener(pubnubListener);
    }

    // Update the state every internal to the kill count based on delta.
    let interval = setInterval(() => {
      if (window.location.pathname === "/") {
        players_copy.forEach(player => {
          if (player.kills_last_update > player.kills)
            player.kills += player.kill_delta_per_interval;
        });
        setPlayers(JSON.parse(JSON.stringify(players_copy)));
      }

    }, INTERVAL_PLAYER_KILL_UPDATE);
    return () => clearInterval(interval);

  }, []);

  return (
      <React.StrictMode>
        <BrowserRouter>
          <Navigation />
          <Routes>
            <Route index element={<Players players={players}  />} />
            <Route path="achievements" element={<Achievements players={players} achievements={achievements}  />} />
            <Route path="economy" element={<Economy retrieveEconomyData={retrieveEconomyData} selling={selling} purchases={purchases} selectedSellers={selectedSellers} bestsellers={bestsellers} onSellerToggle={onSellerToggle} shops={shops} players={players} safehouses={safehouses} />} />
            <Route path="community-center" element={<CommunityCenter players={players} safehouses={safehouses} shops={shops} selectedSellers={selectedSellers}  />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App;