import React from 'react';
import { convert_to_human_time } from '../services/Util';
import {nameToRgb} from "../services/ColorGenerationService";

export default function CommunityCenter({ players, safehouses, shops, selectedSellers }) {

    function distance(x1, y1, x2, y2) {
        return Math.hypot(x2 - x1, y2 - y1);
    }


    React.useEffect(() => {
        const drawShops = (canvas_context) => {
            shops.forEach(shop => {
                let owner_selected = selectedSellers.filter(seller => seller === shop.owner)[0];

                let x = parseInt((shop.x - 11085) * (759 / 277));
                let y = parseInt((shop.y - 8745) * (527 / 195));
                let radius = 3;

                if (owner_selected) {
                    canvas_context.strokeStyle = "rgb(0, 0, 255)";
                    canvas_context.fillStyle = nameToRgb(owner_selected);
                    radius = 5;
                }
                else {
                    canvas_context.strokeStyle = "rgb(255, 255, 255)";
                    canvas_context.fillStyle = "rgb(255, 255, 255)";
                }
                canvas_context.beginPath();
                canvas_context.rect(x, y - radius, radius, radius);
                canvas_context.stroke();
                canvas_context.fill();

            });
        }

        const get_safehouse_owner_at = (x, y) => {

            var owner = undefined;

            safehouses.every(safehouse => {
                if (x >= safehouse.x1 &&
                    y >= safehouse.y1 &&
                    x <= safehouse.x2 &&
                    y <= safehouse.y2) {

                    owner = safehouse.owner;
                    return false;
                }
                else
                    return true;

            });

            return owner;
        }

        const get_player_by = (name) => {

            let obj = players.find(o => o.name === name);

            var result = undefined;
            if (obj !== undefined) {
                result = obj;
            }

            return result;
        }

        const get_shop_owners_at = (x, y) => {
            var owners = [];

            shops.forEach(shop => {
                if (distance(x, y, shop.x, shop.y) < 3) {
                    owners.push(shop.owner);
                }
            })

            if (owners.length === 0)
                return undefined;

            var sellers = [...new Set(owners)].join(", ");

            return sellers;
        }

        const img = new Image();
        var canvas = document.getElementById("mapCanvas");
        var canvas_context = canvas.getContext("2d");

        img.src = "https://www.sundaydrivers.pro/map.jpg";

        canvas_context.fillStyle = "rgba(255, 255, 255, 1)";
        canvas_context.font = "22px corbel";
        canvas_context.fillText("Loading map...", 40, 40);

        // This is necessary to prevent errors trying to draw before the image is loaded.
        img.addEventListener(
            "load",
            () => {
                canvas_context.drawImage(img, 0, 0, 900, 722);
                drawShops(canvas_context);
            },
            false
        );

        var dirty = false;

        canvas.addEventListener('mousemove', (e) => {

            if (players.length === 0)
                return;

            if (dirty) {
                canvas_context.drawImage(img, 0, 0, 900, 722);
                drawShops(canvas_context);
            }

            var x = e.offsetX;
            var y = e.offsetY;

            // Convert drawing coordinates to PZ coordinates
            var x_pz = parseInt(x * (277 / 759) + 11085);
            var y_pz = parseInt(y * (195 / 527) + 8745);

            var safehouse_owner = get_safehouse_owner_at(x_pz, y_pz);
            var shop_owners = get_shop_owners_at(x_pz, y_pz);

            if (safehouse_owner !== undefined) {
                dirty = true;

                var player = get_player_by(safehouse_owner);
                var kills = "N/A";
                var last_seen = "N/A";
                if (player !== undefined) {
                    kills = Math.round(player.kills).toString();
                    last_seen = convert_to_human_time(player.last_login);
                }

                canvas_context.font = "12px corbel";
                var last_seen_width = 20 + canvas_context.measureText("Last seen: " + last_seen).width;

                canvas_context.font = "22px corbel";
                var player_name_width = 20 + canvas_context.measureText(safehouse_owner).width;

                var width = (last_seen_width > player_name_width) ? last_seen_width : player_name_width;

                // Cap to not draw outside the canvas
                if (x + 10 + width > 900) x = 900 - width - 10;
                if (y + 10 + 80 > 730) y = 730 - 80 - 10;

                canvas_context.strokeStyle = "rgb(0, 0, 0)";
                canvas_context.fillStyle = "rgba(255, 255, 255, .8)";
                canvas_context.beginPath();
                canvas_context.rect(10 + x, y - 10, width, 80);
                canvas_context.stroke();
                canvas_context.fill();
                canvas_context.fillStyle = "rgba(0, 0, 0, 1)";
                canvas_context.fillText(safehouse_owner, x + 20, y + 17);
                canvas_context.font = "12px corbel";
                canvas_context.fillText("Kills: " + kills, x + 20, y + 44);
                canvas_context.fillText("Last seen: " + last_seen, x + 20, y + 61);
            }

            if (shop_owners !== undefined) {
                dirty = true;

                canvas_context.font = "12px corbel";
                width = 10 + canvas_context.measureText(shop_owners).width;
                if (width < 80)
                    width = 80;

                // Cap to not draw outside the canvas
                if (x + 10 + width > 900) x = 900 - width - 10;
                if (y + 10 + 80 > 730) y = 730 - 80 - 10;

                canvas_context.strokeStyle = "rgb(0, 0, 0)";
                canvas_context.fillStyle = "rgba(255, 255, 255, .8)";
                canvas_context.beginPath();
                canvas_context.rect(10 + x, y - 10, width, 40);
                canvas_context.stroke();
                canvas_context.fill();
                canvas_context.fillStyle = "rgba(0, 0, 0, 1)";
                canvas_context.fillText(shop_owners, x + 15, y + 22);
                canvas_context.font = "bold 12px corbel";
                canvas_context.fillText("Shops found:", x + 15, y + 7);
            }
        });

        return () => {
        };
    }, [players, safehouses, shops, selectedSellers]);

    return (
        <div className="container">
            <div className="mb-2">
                {selectedSellers.map(seller => <span className="tag ml-2 has-text-white" style={{'backgroundColor': nameToRgb(seller)}}>{seller}</span>)}
            </div>
            <div>
                <canvas id="mapCanvas" width="1081" height="896">
                    Your browser does not support the HTML canvas tag.
                </canvas>
            </div>
        </div>
    );
}