import React from 'react';
import { useState } from 'react';

import {
    convert_to_human_time,
    compare_names,
    compare_hours,
    compare_kills,
    compare_logins,
    compare_sprinters,
    compare_ranks,
    compare_rescues
} from "../services/Util";

function calculate_rank(kills, sprinters) {
    let rank = 1;
    if (sprinters < 500)
        return "";
    else if (sprinters >= 1000 && sprinters < 3000)
        rank = 2;
    else if (sprinters >= 3000)
        rank = 3;

    let subrank = 1;
    if (kills < 1000)
        return "";
    else if (kills < 10000)
        subrank = 2;
    else if (kills < 25000)
        subrank = 3;
    else if (kills < 50000)
        subrank = 4;
    else if (kills >= 50000)
        subrank = 5;

    return "ranks/" + rank.toString() + subrank.toString() + ".png";
}

export default function Players({ players }) {

    const [sortedField, setSortedField] = useState("login");

    if (sortedField === "name") players.sort(compare_names);
    if (sortedField === "hours") players.sort(compare_hours);
    if (sortedField === "kills") players.sort(compare_kills);
    if (sortedField === "login") players.sort(compare_logins);
    if (sortedField === "sprinters") players.sort(compare_sprinters);
    if (sortedField === "rank") players.sort(compare_ranks);
    if (sortedField === "rescues") players.sort(compare_rescues);

    let rows = players
        .filter(player => (Date.now() - Date.parse(player.last_login)) / (1000 * 60 * 60 * 24) < 30)
        .map(player =>
            <tr key={player.name}>
                <td>{player.name}</td>
                <td><img alt="" style={{ float: 'left', height: '23px' }} src={calculate_rank(player.kills, player.sprinters)} /></td>
                <td>{player.hours} hours</td>
                <td>{convert_to_human_time(player.last_login)}</td>
                <td>{player.kills | 0} <span role="img" aria-labelledby="killing zombies">️‍{player.active ? "🔥" : ""}</span></td>
                <td>{player.sprinters.toFixed(0)}</td>
                <td>{player.rescues}</td>
            </tr>);

    return (

        <div className="columns is-centered">
            <div className="column is-four-fifths">
                <div className="columns is-centered">
                    <div className='column is-two-thirds'>
                        <table className="table is-narrow is-fullwidth">
                            <thead>
                                <tr>
                                    <th className="is-clickable" onClick={() => setSortedField("name")}>Player</th>
                                    <th className="is-clickable" onClick={() => setSortedField("rank")}>Rank</th>
                                    <th className="is-clickable" onClick={() => setSortedField("hours")}>Survived</th>
                                    <th className="is-clickable" onClick={() => setSortedField("login")}>Last Login</th>
                                    <th className="is-clickable" onClick={() => setSortedField("kills")}>Kills</th>
                                    <th className="is-clickable" onClick={() => setSortedField("sprinters")}>Sprinters</th>
                                    <th className="is-clickable" onClick={() => setSortedField("rescues")}>Rescues <span><img style={{ float: 'none', height: '28px', marginTop: '-20px' }} alt='' src='new.png'></img></span></th>
                                </tr>
                            </thead>

                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div >
            </div>
        </div>
    )
}
