let global_item_names = {};

export function set_global_item_names(item_names) {
    global_item_names = item_names;
}

export function item_id_to_description(item_id) {

    var result = global_item_names[item_id];
    if (result === undefined)
        result = item_id + "[undefined]";

    return result;

    // const result = item_name.replace('_', '').split(".")
    //     .pop()
    //     .replace(/([A-Z])/g, " $1")
    //     .replace("Magazine Udderly", "Mag")
    //     .substr(0, 30);

    // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    // return finalResult;
}

export function convert_to_human_date(timestamp) {
    return new Date(Date.parse(timestamp)).toDateString();
}

export function truncate(string, maxLength) {
    if (string.length > maxLength) {
        string = string.substring(0, maxLength) + "...";
    }
    return string;
}

export function convert_to_human_time(timestamp) {

    const offset_seconds = new Date().getTimezoneOffset() * 60;

    if (timestamp === undefined)
        return "not available";

    var date = Date.parse(timestamp);

    // Make a fuzzy time
    var delta = Math.round((Date.now() - date) / 1000) + offset_seconds;

    var minute = 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;

    var fuzzy = "unknown";

    switch (true) {
        case (delta < 2 * minute): fuzzy = 'just now'; break;
        case (delta < hour): fuzzy = Math.floor(delta / minute) + ' minutes ago'; break;
        case (Math.floor(delta / hour) === 1): fuzzy = '1 hour ago'; break;
        case (delta < day): fuzzy = Math.floor(delta / hour) + ' hours ago'; break;
        case (delta < day * 2): fuzzy = 'yesterday'; break;
        case (delta < day * 14): fuzzy = Math.floor(delta / day) + ' days ago'; break;
        default: fuzzy = Math.floor(delta / week) + ' weeks ago'; break;
    }

    return fuzzy;
}

// Sort function per name
export function compare_names(a, b) {
    let v1 = a.name.toLowerCase();
    let v2 = b.name.toLowerCase();
    if (v1 < v2) { return -1; }
    if (v1 > v2) { return 1; }
    return 0;
}

// Sort function per player
export function compare_player(a, b) {
    let v1 = a.player.toLowerCase();
    let v2 = b.player.toLowerCase();
    if (v1 < v2) { return -1; }
    if (v1 > v2) { return 1; }
    return 0;
}

// Sort function per hours
export function compare_hours(a, b) {
    if (a.hours < b.hours) { return 1; }
    if (a.hours > b.hours) { return -1; }
    return 0;
}

// Sort function per login
export function compare_logins(a, b) {
    if (a.last_login < b.last_login) { return 1; }
    if (a.last_login > b.last_login) { return -1; }
    return 0;
}

// Sort function per kill
export function compare_kills(a, b) {
    if (a.kills < b.kills) { return 1; }
    if (a.kills > b.kills) { return -1; }
    return 0;
}

// Sort function per created_at
export function compare_created_at(a, b) {
    if (a.created_at > b.created_at) { return -1; }
    if (a.created_at < b.created_at) { return 1; }
    return 0;
}

// Sort function per sprinters
export function compare_sprinters(a, b) {
    if (a.sprinters < b.sprinters) { return 1; }
    if (a.sprinters > b.sprinters) { return -1; }
    return 0;
}

// Sort function per achievement
export function compare_achievement(a, b) {
    if (a.sort_key < b.sort_key) { return 1; }
    if (a.sort_key > b.sort_key) { return -1; }
    return 0;
}

// Sort function per sprinter/kill rank
export function compare_ranks(a, b) {
    let a_tier = 1;
    if (a.sprinters < 500)
        a_tier = 0;
    else if (a.sprinters >= 1000 && a.sprinters < 3000)
        a_tier = 2;
    else if (a.sprinters >= 3000)
        a_tier = 3;

    let b_tier = 1;
    if (b.sprinters < 500)
        b_tier = 0;
    else if (b.sprinters >= 1000 && b.sprinters < 3000)
        b_tier = 2;
    else if (b.sprinters >= 3000)
        b_tier = 3;

    if (a_tier < b_tier) { return 1; }
    if (a_tier > b_tier) { return -1; }

    // Same sprinter tier
    if (a.kills < b.kills) { return 1; }
    if (a.kills > b.kills) { return -1; }
    return 0;
}

// Sort function per rescues
export function compare_rescues(a, b) {
    if (a.rescues < b.rescues) { return 1; }
    if (a.rescues > b.rescues) { return -1; }
    return 0;
}
