import React from 'react';
import { useState } from 'react';
import shopMap from "../resources/images/SquareMap.png"

import {
    convert_to_human_time,
    compare_names,
    compare_created_at,
    item_id_to_description,
    truncate
} from "../services/Util";
import {nameToRgb} from "../services/ColorGenerationService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faRefresh} from "@fortawesome/free-solid-svg-icons";

export default function Economy({ selling, purchases, selectedSellers, bestsellers, onSellerToggle, shops, retrieveEconomyData }) {

    const [filter, setFilter] = useState("");
    const [sortFunction, setSortFunction] = useState(() => compare_created_at);
    const [displayCards, setDisplayCards] = useState('true' === localStorage.getItem("display_pkmn_cards"));

    const handleFilterChange = (event) => {
        let value = event.target.value;
        if (value.length < 2) value = "";
        setFilter(value.toLowerCase());
    }

    const togglePkmnCardsFiltered = () => {
        localStorage.setItem("display_pkmn_cards", !displayCards);
        setDisplayCards(!displayCards);
    }

    const itemFilterFunction = (item) => {
        if (displayCards === false && item.item.search("pkmncards") === 0) return false;
        if (filter.length < 2) return true;

        const value = item.name.toLowerCase() + item.sellers.join().toLowerCase();
        return (value.search(filter) >= 0) && !item.item.toLowerCase().includes("moveables");
    }

    const getSellerTagClass = (seller) => {
        const result = "tag is-clickable";
        return selectedSellers.includes(seller) ? `${result} is-info` : `${result}  is-warning`;
    }

    const sellersFilterFunction = (item) => {
        return item.sellers.filter((value, index, array) => array.indexOf(value) === index).slice(0, 10)
    }

    const sorted = selling.map(item => ({...item, name: item_id_to_description(item.item)}))
        .filter(itemFilterFunction)
        .sort(sortFunction);

    const drawShops = (canvas_context) => {
        shops.forEach(shop => {
            let owner_selected = selectedSellers.filter(seller => seller === shop.owner)[0];

            let x = parseInt((shop.x - 11246) * (759 / 277));
            let y = parseInt((shop.y - 8774) * (527 / 195));
            let radius = 3;

            if (owner_selected) {
                canvas_context.strokeStyle = "rgb(255, 255, 255)";
                canvas_context.fillStyle = nameToRgb(owner_selected);
                radius = 5;
            }
            else {
                canvas_context.strokeStyle = "rgb(255, 255, 0)";
                canvas_context.fillStyle = "rgba(255, 255, 0, .7)";
            }
            canvas_context.beginPath();
            canvas_context.rect(x, y - radius, radius, radius);
            canvas_context.stroke();
            canvas_context.fill();

        });
    }

    const initCanvas = () => {
        const img = new Image();
        const canvas = document.getElementById("shops-canvas");
        const canvas_context = canvas.getContext("2d");

        img.src = shopMap;

        canvas_context.fillStyle = "rgba(255, 255, 255, 1)";
        canvas_context.font = "22px corbel";
        canvas_context.fillText("Loading map...", 40, 40);

        // This is necessary to prevent errors trying to draw before the image is loaded.
        img.addEventListener(
            "load",
            () => {
                canvas_context.drawImage(img, 0, 0, 450, 450);
                drawShops(canvas_context);
            },
            false
        );
    }

    React.useEffect(() => {
        initCanvas();
    });

    return (
        <div>
            <div className='columns is-centered'>
                <div className='column is-four-fifths'>
                    <div className='columns'>
                        <div className="column is-two-thirds">
                            <nav className="panel">
                                <p className="panel-heading">
                                    Available at Community Center Player Shops
                                </p>
                                <div className="panel-block">
                                    <div className="block">
                                        <label className="checkbox pr-1">
                                            <input type="checkbox" checked={displayCards} onChange={togglePkmnCardsFiltered} />
                                        </label>
                                        Display PKMN Cards
                                    </div>
                                </div>
                                <div className="panel-block">
                                    <p className="control has-icons-left">
                                        <input className="input" type="text" placeholder="Search" onChange={handleFilterChange} />
                                        <span className="icon is-left">
                                            <FontAwesomeIcon className="has-text-grey-dark" icon={faMagnifyingGlass} />
                                        </span>
                                    </p>
                                    <button className="button ml-1" onClick={retrieveEconomyData}>
                                        <FontAwesomeIcon className="has-text-grey" icon={faRefresh} />
                                    </button>
                                </div>
                                <div className="panel-block">
                                    <table className="table is-narrow is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th className="is-narrow is-clickable" onClick={() => setSortFunction(() => compare_names)}>Item</th>
                                                <th className="is-narrow">Quantity</th>
                                                <th className="is-narrow">Seller(s)</th>
                                                <th className="is-narrow is-clickable" onClick={() => setSortFunction(() => compare_created_at)}>Added</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {sorted
                                                .map(item => {
                                                    console.log(item);
                                                        return <tr key={item.id}>
                                                            <td>{truncate(item.name, 25)}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>

                                                                <div className="tags">
                                                                    {
                                                                        sellersFilterFunction(item).map((seller) => {
                                                                            return (
                                                                                <span
                                                                                    key={seller}
                                                                                    style={{
                                                                                        marginTop: "3px",
                                                                                        paddingTop: "1px",
                                                                                        height: "1.5em"
                                                                                    }}
                                                                                 onClick={() => onSellerToggle(seller)}
                                                                                 className={getSellerTagClass(seller)}
                                                                                >
                                                                                    {seller}
                                                                                </span>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>

                                                            </td>
                                                            <td>{convert_to_human_time(item.created_at)}</td>
                                                        </tr>
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </nav>
                        </div>


                        <div className="column is-one-third" style={{maxHeight: "100vh", overflow: "scroll", position: "sticky", top: "50px"}}>
                            <div className="panel">
                                <canvas
                                    id="shops-canvas"
                                    width="450"
                                    height="450"
                                    style={{
                                        "border": "7px solid #7e736b",
                                        "borderRadius": "5px"
                                    }}
                                >
                                    Your browser does not support the HTML canvas tag.
                                </canvas>
                                <div className="mt-2">
                                    <div className="field is-grouped is-grouped-multiline">
                                    {selectedSellers.map(seller =>
                                        <div className="control">
                                            <div className="tags has-addons ml-2 is-clickable" onClick={() => onSellerToggle(seller)}>
                                                <span className="tag is-delete"> </span>
                                                <span className="tag has-text-white" style={{'backgroundColor': nameToRgb(seller)}}>
                                                        {seller}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                            <nav className="panel">
                                <p className="panel-heading">
                                    Best sellers (last 30 days)
                                    <span><img style={{ float: 'right', height: '50px', marginTop: '-25px' }} alt='' src='best-selling.png'></img></span>
                                </p>
                                <div className="panel-block">
                                    <table className="table is-narrow is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {bestsellers
                                                .map(item =>
                                                    <tr key={item.id}>
                                                        <td>{truncate(item_id_to_description(item.item), 20)}</td>
                                                        <td>{item.quantity}</td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </nav>
                            <nav className="panel">
                                <p className="panel-heading">
                                    Last items sold at shops
                                </p>
                                <div className="panel-block">
                                    <table className="table is-narrow is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>When</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {purchases
                                                .map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item_id_to_description(item.item)}</td>
                                                        <td>{convert_to_human_time(item.created_at)}</td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
